import { Modal, Button, Row } from "antd";
import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import RepairService from "services/mtns/order-history/RepairService";
import { useReactToPrint } from "react-to-print";

const QrCode = ({ togglePrint, setPrint, dataRecord }: any) => {
  const componentRef: any = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleCancel = () => {
    setPrint(false);
  };

  const PrintArea = styled.div`
    width: 80mm;
    height: 40mm;
    border: 1px solid black;
    padding: 2mm;
    margin-right: 10px;
    box-sizing: border-box;
    display: flex;
  `;

  const [imgQr, setImgQr]: any = useState("");
  const dialogOpened = async () => {
    const { data } = await RepairService.getRepairPartQr(dataRecord?.id);
    setImgQr(data?.data?.qrcode);
  };

  useEffect(() => {
    if (togglePrint) {
      dialogOpened();
    }
  }, [togglePrint]);

  return (
    <>
      {/* dialog element */}
      <Modal
        forceRender
        open={togglePrint}
        title="Print Part"
        onCancel={() => handleCancel()}
        footer={[
          <Button onClick={() => handleCancel()} key="back">
            Cancel
          </Button>,
        ]}
      >
        <Row align="middle" className="mb-5">
          <PrintArea ref={componentRef}>
            <div style={{ width: "55%", fontSize: "10px", lineHeight: "19px" }}>
              <div>
                <span style={{ width: "20%", display: "inline-block" }}>
                  ID
                </span>
                <span>:</span>
                <span> {dataRecord?.part_repair_number}</span>
              </div>
              <div>
                <span style={{ width: "20%", display: "inline-block" }}>
                  Name
                </span>
                <span>:</span>

                <span> {dataRecord?.part_name}</span>
              </div>
              <div>
                <span style={{ width: "20%", display: "inline-block" }}>
                  Size
                </span>
                <span>:</span>

                <span> {dataRecord?.size}</span>
              </div>
              <div>
                <span style={{ width: "20%", display: "inline-block" }}>
                  Type
                </span>
                <span>:</span>

                <span> {dataRecord?.type}</span>
              </div>
              <div>
                <span style={{ width: "20%", display: "inline-block" }}>
                  Maker
                </span>
                <span>:</span>

                <span> {dataRecord?.maker_name}</span>
              </div>
              <div>
                <span style={{ width: "20%", display: "inline-block" }}>
                  Store
                </span>
                <span>:</span>

                <span> {dataRecord?.store_code}</span>
              </div>
            </div>

            {/* <Row> */}
            <div style={{ width: "45%" }}>
              <img src={imgQr} style={{ width: "100%" }} alt="qr" />
            </div>
            {/* <Col span={10}>
            </Col> */}
            {/* </Row> */}
          </PrintArea>
          <Button onClick={handlePrint} type="primary">
            Print QR
          </Button>
        </Row>
      </Modal>
    </>
  );
};

export default QrCode;
