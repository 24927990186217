import xhrInstance from "services/config";
import objToParams from "utils/objToParams";

const createRepairPart = (params: any) => {
  return xhrInstance.post("/mtn/part-repair/create", params);
};

const getRepairPart = (params: any) => {
  return xhrInstance.get(objToParams("/mtn/part-repair", params));
};

const getRepairPartById = (id: any) => {
  return xhrInstance.get(`/mtn/part-repair/get/${id}`);
};

const outPart = (params: any) => {
  return xhrInstance.post(`/mtn/part/out/${params.id}`, params.body);
};

const removePart = (id: string | number) => {
  return xhrInstance.delete(`/mtn/part/${id}`);
};

const updateRepairPart = (params: any) => {
  return xhrInstance.put(`/mtn/part-repair/update/${params.id}`, params.body);
};

const updateRepairPartStatus = (params: any) => {
  return xhrInstance.put(
    `/mtn/part-repair/update-status/${params.id}`,
    params.body
  );
};

const getRepairPartList = (params: any) => {
  return xhrInstance.get(
    objToParams("/mtn/part-repair/list-part-repair", params)
  );
};

const getRepairPartStatus = (params: any) => {
  return xhrInstance.get(objToParams("/mtn/part-repair/list-status", params));
};

const getFilterRepairPartStatus = () => {
  return xhrInstance.get("/mtn/part-repair/list-filter-status");
};

const getRepairPartQr = (id: number | string) => {
  return xhrInstance.get(`/mtn/part-repair/print/${id}`);
};

const RepairService = {
  createRepairPart,
  getRepairPart,
  outPart,
  removePart,
  updateRepairPart,
  getRepairPartList,
  getRepairPartStatus,
  updateRepairPartStatus,
  getRepairPartById,
  getFilterRepairPartStatus,
  getRepairPartQr,
};

export default RepairService;
