import { useState } from "react";
import { Card, Row, Button, Pagination, Table, Tooltip, Modal } from "antd";
import { AppDispatch } from "stores";
import { useSelector, useDispatch } from "react-redux";
import { get } from "stores/order-history/repair/actions/repair";
import {
  EditOutlined,
  UnorderedListOutlined,
  InteractionOutlined,
  PrinterFilled,
} from "@ant-design/icons";
import UpdateRepairDialog from "pages/mtn/order-history/repair/sections/tabs/repair/table-pagination/dialog/update";
import StatusRepairDialog from "pages/mtn/order-history/repair/sections/tabs/repair/table-pagination/dialog/status";
import {
  toggleUpdateDialog,
  toggleStatusDialog,
  setPageSize,
} from "stores/order-history/repair";
import DetailRepairPartDialog from "pages/mtn/order-history/repair/sections/tabs/repair/table-pagination/dialog/detail";
import DetailPrint from "pages/mtn/order-history/repair/sections/tabs/repair/table-pagination/dialog/qrcode";

const TablePagination = () => {
  const [currentRecord, setCurrentRecord] = useState<any>({});
  const [modal, contextHolder] = Modal.useModal();
  const {
    isLoadingData,
    currentPage,
    totalData,
    search,
    pageSize,
    datas,
    activeTab,
  } = useSelector((state: any) => state.repairPart);

  const newDatas = datas?.map((item: any, index: number) => {
    return {
      ...item,
      no: pageSize * (currentPage - 1) + index + 1,
    };
  });

  const dispatch = useDispatch<AppDispatch>();

  const handleChangeStatus = (record: any) => {
    setCurrentRecord(record);
    dispatch(toggleStatusDialog(true));
  };

  const handleUpdate = (record: any) => {
    setCurrentRecord(record);
    dispatch(toggleUpdateDialog(true));
  };

  const handlePrint = (record: any) => {
    setCurrentRecord(record);
    setPrint(true);
  };

  const handlePaginate = async (page: number, pageSize: number) => {
    dispatch(setPageSize(pageSize));
    const params: any = {
      page: page,
      per_page: pageSize,
      keyword: search,
      status_id: activeTab,
    };

    if (search) {
      params.keyword = search;
    }

    await dispatch(get(params)).unwrap();
  };

  const columns: any = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 60,
      align: "center",
    },
    {
      title: "ID Part",
      dataIndex: "part_repair_number",
      key: "id_part",
      width: 200,
    },
    {
      title: "Name",
      dataIndex: "part_name",
      key: "name",
      width: 150,
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      width: 150,
    },
    {
      title: "Quantity",
      dataIndex: "qty",
      key: "qty",
      width: 100,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 150,
    },
    {
      title: "Maker",
      dataIndex: "maker_name",
      key: "maker_name",
      width: 150,
      render: (r: any) => r || "-",
    },
    {
      title: "Supplier",
      dataIndex: "supplier_name",
      key: "supplier_name",
      width: 150,
      render: (r: any) => r || "-",
    },
    {
      title: "Store",
      dataIndex: "store_name",
      key: "store",
      width: 150,
    },
    {
      title: "Status",
      dataIndex: "status_name",
      key: "status_name",
      width: 150,
    },
    {
      title: "Options",
      key: "options",
      width: 150,
      fixed: "right",
      render: (dataRecord: any) => {
        return (
          <Row justify="space-between">
            {activeTab === 200 ? (
              <Tooltip title="Print Part">
                <Button
                  onClick={() => handlePrint(dataRecord)}
                  type="primary"
                  shape="circle"
                  icon={<PrinterFilled />}
                />
              </Tooltip>
            ) : (
              <>
                <Tooltip title="Edit">
                  <Button
                    onClick={() => handleUpdate(dataRecord)}
                    type="primary"
                    shape="circle"
                    icon={<EditOutlined />}
                  />
                </Tooltip>

                <Tooltip title="Change Status">
                  <Button
                    onClick={() => handleChangeStatus(dataRecord)}
                    type="primary"
                    shape="circle"
                    icon={<InteractionOutlined />}
                  />
                </Tooltip>

                <Tooltip title="Detail">
                  <Button
                    onClick={() => {
                      setCurrentRecord(dataRecord);
                      setToggleDetail(true);
                    }}
                    type="primary"
                    shape="circle"
                    icon={<UnorderedListOutlined />}
                  />
                </Tooltip>
              </>
            )}
          </Row>
        );
      },
    },
  ];

  const getColumns = () => {
    if (activeTab === 100) {
      return columns;
    } else if (activeTab === 200) {
      return columns;
    } else {
      return columns?.filter((item: any) => item.title !== "Options");
    }
  };

  const [toggleDetail, setToggleDetail] = useState(false);
  const [togglePrint, setPrint] = useState(false);

  return (
    <Card>
      {contextHolder}
      {/* <UpdateRepairDialog dataRecord={currentRecord} /> */}
      <StatusRepairDialog dataRecord={currentRecord} />
      <UpdateRepairDialog dataRecord={currentRecord} />

      <DetailRepairPartDialog
        toggleDetail={toggleDetail}
        setToggleDetail={setToggleDetail}
        dataRecord={currentRecord}
      />
      <DetailPrint
        togglePrint={togglePrint}
        setPrint={setPrint}
        dataRecord={currentRecord}
      />

      <Table
        rowKey={(record: any) => record.id}
        loading={isLoadingData}
        columns={getColumns()}
        dataSource={newDatas}
        pagination={false}
        scroll={{ x: "calc(700px + 50%)" }}
      />
      <Row className="mt-5" justify="end">
        <Pagination
          current={currentPage}
          total={totalData}
          showSizeChanger
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={(page, pageSize) => handlePaginate(page, pageSize)}
        />
      </Row>
    </Card>
  );
};

export default TablePagination;
