import { useCallback, useMemo, useEffect, useState } from "react";
import { Card, Input, Row, Col, Button, Tabs } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import DialogCreateStore from "pages/mtn/order-history/repair/sections/action-table/dialog/create";
import {
  toggleAddDialog,
  setSearch,
  setActiveTab,
} from "stores/order-history/repair";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "stores";
import * as partAction from "stores/order-history/repair/actions/repair";
import debounce from "lodash/debounce";

const ActionTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { search, pageSize, isLoadingSearch, activeTab, optionTabs } =
    useSelector((state: any) => state.repairPart);

  const sendBackendRequest = useCallback((value: any) => {
    dispatch(partAction.search(value)).unwrap();
  }, []);

  const debouncedSendRequest = useMemo(() => {
    return debounce(sendBackendRequest, 500);
  }, [sendBackendRequest]);

  const handleChange = (e: any) => {
    const value = e.target.value;
    const params = {
      page: 1,
      per_page: pageSize,
      keyword: value,
      status_id: activeTab,
    };
    dispatch(setSearch(value));
    debouncedSendRequest(params);
  };

  const handleChangeTab = (tab: string) => {
    dispatch(setActiveTab(tab));
  };

  const onSearch = (val: string) => {
    const params = {
      page: 1,
      per_page: pageSize,
      keyword: val,
      status_id: activeTab,
    };
    sendBackendRequest(params);
  };

  return (
    <>
      <Card className="mb-3">
        <Tabs
          activeKey={activeTab || 100}
          onChange={handleChangeTab}
          type="card"
          items={optionTabs}
        />
        <Row justify="space-between" gutter={10}>
          <Col span={6}>
            <Input.Search
              loading={isLoadingSearch}
              value={search}
              placeholder="Search"
              onChange={handleChange}
              onSearch={() => onSearch(search)}
              enterButton
            />
          </Col>
          <Col span={6}></Col>
          <Col span={12}>
            {activeTab === 100 && (
              <Row justify="end">
                <Button
                  onClick={() => dispatch(toggleAddDialog(true))}
                  type="primary"
                  icon={<PlusOutlined />}
                >
                  Create
                </Button>
              </Row>
            )}
          </Col>
        </Row>
      </Card>
      <DialogCreateStore />
    </>
  );
};

export default ActionTable;
